<template>
    <section class="box-animation">
        <footer>
            <img class="cover" src="@/assets/img/home-page/lary-footer.webp" alt="">
            <div class="row content">
                <div class="col-md-5 mb-3">
                    <h1 class="title">
                      <span class="neon-text">L</span>azurde
                    </h1>
                    <p>
                        Nine out of ten doctors recommend Laracasts over competing brands. Come inside, see for yourself, and massively level up your development skills in the process. 
                    </p>
                    <div class="mt-3 info-address">
                        <div class="d-flex align-items-center mb-3">
                        <i class="fa-solid fa-location-dot neon-text"></i>
                        <span>Syria, Damscus, Damscus</span>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <i class="fa-solid fa-phone neon-text"></i>
                        <span> 963 933 080 648</span>
                    </div>
                    </div>
                    <ul class="d-flex align-items-center mt-5 social">
                        <li>
                            <a href="" target="_blank">
                                <i class="fa-brands fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="" target="_blank">
                                <i class="fa-brands fa-x-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="" target="_blank">
                                <i class="fa-brands fa-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-1 mb-3">
                </div>
                <div class="col-md-2 mb-3">
                    <h5>Help</h5>
                    <ul>
                        <li>
                            <router-link to="/">
                                FAQ
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Term & conditions
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Privacy
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                FAQ
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Term & conditions
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Privacy
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h5>Useful Links</h5>
                    <ul>
                        <li>
                            <router-link to="/">
                                About
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                News
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Contacts
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                About
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                News
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Contacts
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                About
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h5>Help</h5>
                    <ul>
                        <li>
                            <router-link to="/">
                                FAQ
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Term & conditions
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                Privacy
                            </router-link>
                        </li>
                    </ul>
                </div>
               <div class="container">
                    <div class="bottom-footer pt-4">
                        <p>© Lazurde 2024. All rights reserved. Yes, all of them. That means you, Todd. </p>
                        <p>Proudly hosted with Laravel Forge and DigitalOcean. </p>
                    </div>
               </div>
            </div>
        </footer>
    </section>
</template>